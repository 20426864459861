<template>
  <div class="districon">
    <div class="btn">
      <el-button type="primary" @click="add">新增</el-button>
    </div>
    <div class="tablediv">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          fixed
          prop="ProvinceName "
          align="center"
          label="不可下单地区"
        >
          <template slot-scope="scope">
            <div
              v-for="(item, index) in scope.row.areaJson"
              :key="item.province_id + index"
            >
              <span v-if="index == 0">
                {{
                  item.province_name != undefined
                    ? item.province_name + "/"
                    : ""
                }}
                {{ item.city_name != undefined ? item.city_name + "/" : "" }}
                {{
                  item.district_name != undefined
                    ? item.district_name + "/"
                    : ""
                }}
                {{
                  item.street_name != undefined ? item.street_name  : ""
                }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="快递公司" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.companyName}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="forbidLogistics"
          align="center"
          label="不可发货原因"
        >
        </el-table-column>
        <el-table-column prop="admPlatformName" align="center" label="关联平台">
        </el-table-column>
        <el-table-column prop="start" align="center" label="开启/关闭">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.start"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              @change="changestart(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="look(scope.row)"
              >查看</el-button
            >
            <el-button type="text" size="small" @click="Edit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="delet(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <div v-if="dialogVisible2">
      <Noaddressaction
        :title="title"
        @closeModel="closemo"
        :optionsAddressList="optionsAddress"
        :josnlist="josnlist"
        ref="noadd"
      ></Noaddressaction>
    </div>
  </div>
</template>

<script>
import {
  GetShipmentsList,
  GaodeDistrict,
  UpShipments,
} from "../../api/nologistics";
import Noaddressaction from "../../components/noaddress/noAddressAction.vue";
export default {
  components: { Noaddressaction },
  data() {
    return {
      josnlist: {},
      title: "新增不可发货区域",
      dialogVisible2: false,
      total: 0,
      values: [],
      value: "",
      props: { multiple: true },
      optionsAddress: [],
      tableData: [],
      express: [],
      csuse: [],
      dynamicTags: [],
      pagesize: 10,
      currentPage: 1,
      dialogVisible: false,
      ruleForm: {
        area: "",
        express: [],
        cause: "",
        platform: "",
      },
    };
  },
  created() {
    this.getlogist();
    this.getGaodeDistrict();
  },
  methods: {
    changestart(row) {
      row.isDelete = false;
      row.areaJson = JSON.stringify(row.areaJson);
      row.start = new Boolean(row.start);
      this.edtUpShipments(row);
    },

    //省市区街道4级接口请求
    getGaodeDistrict() {
      GaodeDistrict({ subdistrict: 4 })
        .then((res) => {
          this.optionsAddress = this.removeDistrictsFromStreet(
            res.districts[0].districts
          );
        })
        .catch((err) => {});
    }, //联动城市列表-控制联动 省市区街道4级数据处理
    removeDistrictsFromStreet(data) {
      return data.map((item) => {
        if (item.level === "street") {
          delete item.districts;
        } else if (item.districts) {
          if (item.districts.length == 0) {
            delete item.districts;
          } else {
            item.districts = this.removeDistrictsFromStreet(item.districts);
          }
        }
        return item;
      });
    },

    closemo() {
      this.dialogVisible2 = false;
      this.getlogist();
    },

    //获取不可发货物流列表
    getlogist() {
      let data = {
        pageIndex: this.currentPage,
        pageSiz: this.pagesize,
      };
      GetShipmentsList(data).then((res) => {
        // console.log(res);
        if (res.code == 10000) {
          this.tableData = res.data;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].companyName=this.tableData[i].companyName.substring(0,this.tableData[i].companyName.length-1)
            this.tableData[i].areaJson = JSON.parse(this.tableData[i].areaJson);
            for (let t = 0; t < this.tableData[i].areaJson.length; t++) {
              this.tyd = `${this.year}/${this.mouth}/${this.day}`;
            }
          }
          console.log(this.tableData, "this.tableData");
          this.total = res.total;
        }
      });
    },
    cascchange(val) {
      console.log(val);
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.getlogist();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getlogist();
    },
    handleClose() {},

    Edit(row) {
      this.josnlist = row;
      this.title = "编辑不可发货区域";
      this.dialogVisible2 = true;
      // this.$refs.noadd.viewDetail = true;
    },
    look(row) {
      this.josnlist = row;
      this.title = "查看不可发货区域";
      this.dialogVisible2 = true;
    },
    add() {
      this.title = "新增不可发货区域";
      this.dialogVisible2 = true;
      this.$nextTick(() => {
        this.$refs.noadd.closefrom();
      });
    },
    handleChange() {},
    handleClosetag(tag) {
      console.log(tag);
    },
    delet(row) {
      this.$confirm("是否删除该不可发货模板", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (row.start == true) {
            this.$message.error("请先关闭不可发货设置");
          } else {
            row.areaJson = JSON.stringify(row.areaJson);
            row.start = new Boolean(row.start);
            row.isDelete = true;
            this.delettUpShipments(row);
            this.getlogist();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delettUpShipments(row) {
      UpShipments(row).then((res) => {
        console.log(res);
        if (res.code == 10000) {
          this.$message({
            message: "删除不可发货模板成功",
            type: "success",
          });
        }
        this.getlogist();
      });
    },
    edtUpShipments(row) {
      UpShipments(row).then((res) => {
        console.log(res);
        if (res.code == 10000) {
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
        this.getlogist();
      });
    },
  },
};
</script>

<style scoped>
.districon {
  width: 100%;
  height: 100%;
  background: white;
  margin-top: 10px;
}
.btn {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  float: right;
}
.tablediv {
  margin-top: 15px;
}
.block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.frame .el-cascader {
  width: 300px;
}
.frame .el-form-item .el-input {
  width: 300px;
}

.frame .el-form-item .el-select {
  width: 300px;
}
.text {
  font-size: 18px;
  font-weight: bold;
  margin-left: 15px;
  margin-top: 15px;
}
.endall {
  width: 100%;
  height: 300px;
  border: 1px solid #dddd;
  margin-top: 20px;
  overflow-y: scroll;
}
.endall .el-tag.el-tag--info {
  float: left;
  margin-left: 10px;
  margin-top: 10px;
}
.footer {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>