<template>
  <el-dialog
    width="1100px"
    :title="titles"
    :visible.sync="viewDetail"
    :close-on-click-modal="false"
    @close="closeModel"
  >
    <div class="main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm com-form"
      >
        <el-form-item label="添加区域" prop="areaList">
          <el-cascader
            :disabled="titles == '查看不可发货区域'"
            v-model="ruleForm.areaList"
            :options="optionsAddress"
            :props="props"
            clearable
            ref="cascaderAreaList"
            :expand-all="true"
            @change="handleChangeCascade"
            popper-class="pc-sel-area-cascader"
          >
          </el-cascader>
          <!--            @visible-change="handleCascadeClick"
             <div class="btn-group" v-show="showButtons">
            <el-button type="primary" @click.stop="selectAll" size="mini"
              >全选</el-button
            >
            <el-button @click="selectNone" size="mini">反选</el-button>
          </div> -->
        </el-form-item>

        <el-form-item label="添加快递" prop="expressCompany">
          <el-select
            :disabled="titles == '查看不可发货区域'"
            v-model="ruleForm.expressCompany"
            placeholder="请选择快递公司"
            multiple
            clearable
          >
            <el-option
              v-for="item in expressCompanyList"
              :key="item.companyId"
              :label="item.name"
              :value="item.companyId"
              @change="changecom"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="不可发货原因" prop="undeliverableReason">
          <el-select
            :disabled="titles == '查看不可发货区域'"
            v-model="ruleForm.undeliverableReason"
            placeholder="请选择不可发货原因"
            @change="changeceas"
            clearable
          >
            <el-option
              v-for="item in undeliverableReasonList"
              :key="item.forbidLogisticsId"
              :label="item.describe"
              :value="item.forbidLogisticsId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联平台" prop="associatedPlatform">
          <el-select
            :disabled="titles == '查看不可发货区域'"
            v-model="ruleForm.associatedPlatform"
            placeholder="请选择平台"
            clearable
          >
            <el-option
              v-for="item in associatedPlatformList"
              :key="item.platformId"
              :label="item.name"
              :value="item.platformId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="selectAddress">
        <h4>已选择区域</h4>
        <div class="selectContent">
          <el-tag
            type="info"
            :key="index"
            v-for="(tag, index) in selectAreaList"
            closable
            :disable-transitions="false"
            style="margin: 0px 10px 10px 0"
          >
            {{ tag }}
          </el-tag>
        </div>
      </div>
    </div>

    <div
      v-if="titles !== '查看不可发货区域'"
      slot="footer"
      class="dialog-footer"
    >
      <el-button v-loading="addload" type="primary" @click="handleSubmit"
        >保 存</el-button
      >
      <el-button @click="closeModel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import jsonp from "jsonp";
import {
  ListPlatform,
  GetForbidLogisticsList,
  GetLogisticsCompanyLists,
  district,
  GaodeDistrict,
  AddShipments,
  UpShipments,
} from "../../api/nologistics";
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    optionsAddressList: {
      required: true,
      type: Array,
    },
    josnlist: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      addload: false,
      titles: "",
      viewDetail: true,
      josnpd: {},
      loading: false,
      showButtons: false,
      ruleForm: {
        areaList: [], //选区域数据
        expressCompany: [], //选中快递公司地址,
        undeliverableReason: "", //选中不可发货原因
        associatedPlatform: "", //选中关联平台
      },
      rules: {
        areaList: [
          { required: true, message: "请添加区域", trigger: "change" },
        ],
        expressCompany: [
          { required: true, message: "请选择快递公司", trigger: "change" },
        ],
        undeliverableReason: [
          { required: true, message: "请选择不可发货原因", trigger: "change" },
        ],
        associatedPlatform: [
          { required: true, message: "请选择平台", trigger: "change" },
        ],
      },
      //区域地址
      optionsAddress: [],
      props: {
        multiple: true,
        expandTrigger: "hover",
        value: "name",
        label: "name",
        children: "districts",
        // checkStrictly: true
      },
      expressCompanyList: [], //快递公司地址数据
      undeliverableReasonList: [], //不可发货原因数据
      associatedPlatformList: [], //关联平台数据
      selectAreaList: [],
      expandALL: false,
    };
  },
  watch: {
    optionsAddressList: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val, "val-----");
        this.optionsAddress = val;
      },
    },
    josnlist: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val, "val---");
        this.josnpd = val;
        if (this.josnpd.areaJson) {
          /*  this.ruleForm.areaList = this.josnpd.areaJson; */
          this.ruleForm.associatedPlatform = this.josnpd.platformId;
          this.ruleForm.undeliverableReason = this.josnpd.forbidLogisticsId;
          let comparr = [];
          this.josnpd.companyId.split(",").forEach((item) => {
            comparr.push(Number(item));
          });
          this.ruleForm.expressCompany = comparr;

          let arrFormList = [];
          this.josnpd.areaJson.forEach((item) => {
            let arr = [];
            if (item.province_name) {
              arr.push(item.province_name);
            }
            if (item.city_name) {
              arr.push(item.city_name);
            }
            if (item.district_name) {
              arr.push(item.district_name);
            }
            if (item.street_name) {
              arr.push(item.street_name);
            }
            arrFormList.push(arr);
          });

          this.ruleForm.areaList = arrFormList;
          console.log(this.ruleForm.areaList, "this.ruleForm.areaList");

          if (arrFormList.length > 0) {
            this.selectAreaList = [];
            this.selectAreaList = arrFormList.map((selectedValue) => {
              return selectedValue.join("/");
            });
          }
        }
      },
    },
  },

  mounted() {
    this.getListPlatform();
    this.getForbidLogistics();
    this.getlogiunstics();
    //this.getdistrict();
    this.titles = this.title;
  },

  methods: {
    getlogiunstics() {
      GetLogisticsCompanyLists().then((res) => {
        if (res.code == 10000) {
          this.expressCompanyList = res.data;
        }
      });
    },

    changeceas(val) {
      console.log(val);
    },
    changecom(val) {
      console.log(val);
    },

    //不可发货原因列表
    getForbidLogistics() {
      GetForbidLogisticsList().then((res) => {
        if (res.code == 10000) {
          this.undeliverableReasonList = res.data;
        }
      });
    },

    //获取平台列表

    getListPlatform() {
      ListPlatform().then((res) => {
        if (res.code == 10000) {
          this.associatedPlatformList = res.data;
        }
      });
    },
    closefrom() {
      this.ruleForm = {
        areaList: [], //选区域数据
        expressCompany: [], //选中快递公司地址,
        undeliverableReason: "", //选中不可发货原因
        associatedPlatform: "", //选中关联平台
      };
      this.selectAreaList = [];
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
    },

    closeModel() {
      this.$emit("closeModel");
      this.ruleForm = {
        areaList: [], //选区域数据
        expressCompany: [], //选中快递公司地址,
        undeliverableReason: "", //选中不可发货原因
        associatedPlatform: "", //选中关联平台
      };
    },
    //反选全选按钮显示
    handleCascadeClick(value) {
      this.showButtons = value;
    },
    // // 全选所有区域
    selectAll() {
      //获取全部数据的二维数组 进行全选赋值areaList
      this.ruleForm.areaList = this.getAllOptionValues(this.optionsAddress);
      //选中区域处理label的处理拼接
      this.selectAreaList = this.ruleForm.areaList.map((selectedValue, index) =>
        this.getLabelByValue(selectedValue, this.optionsAddress)
      );
    },
    //全选数据处理
    getAllOptionValues(options) {
      const values = [];
      function traverse(arr, path = []) {
        for (const option of arr) {
          const newPath = [...path, option.value];
          if (!option.children || option.children.length === 0) {
            values.push(newPath);
          } else {
            traverse(option.children, newPath);
          }
        }
      }
      traverse(options);
      return values;
    },

    // 反选所有区域
    selectNone() {
      //获取全部数据、进行已选中的删除 没选中的添加
      const allSelectedValues = this.getAllOptionValues(this.optionsAddress);
      const selectedValues = this.ruleForm.areaList;
      const selectNoneArray = this.findMissingSubarrays(
        allSelectedValues,
        selectedValues
      );
      this.ruleForm.areaList = selectNoneArray;
      //选中区域处理label的处理拼接
      this.selectAreaList = this.ruleForm.areaList.map((selectedValue, index) =>
        this.getLabelByValue(selectedValue, this.optionsAddress)
      );
    },
    //数据处理
    findMissingSubarrays(arr, brr) {
      const map = new Map();
      // 将 brr的每个子数组转化字符串，并将其作为键存储在 Map 中
      for (const subarr of brr) {
        const key = subarr.join(",");
        map.set(key, true);
      }
      const missingSubarrays = [];
      for (const subarr of arr) {
        const key = subarr.join(",");
        if (!map.has(key)) {
          missingSubarrays.push(subarr);
        }
      }
      return missingSubarrays;
    },
    //获取点击组件选中区域
    handleChangeCascade(selectedOptions) {
      console.log(selectedOptions);
      this.selectAreaList = selectedOptions.map((selectedValue) =>
        this.getLabelByValue(selectedValue, this.optionsAddress)
      );
      console.log(this.ruleForm.areaList, "9999999999999999999999999999");
    },
    //获取label的数据
    getLabelByValue(selectedValue, options) {
      const labels = [];
      const findLabel = (options, value, currentLevel) => {
        for (const option of options) {
          if (option.name === value) {
            labels.push(option.name);
            if (option.districts && selectedValue.length > currentLevel + 1) {
              findLabel(
                option.districts,
                selectedValue[currentLevel + 1],
                currentLevel + 1
              );
            }
            break;
          }
        }
      };
      if (selectedValue.length > 0) {
        findLabel(options, selectedValue[0], 0);
      }
      return labels.join("/");
    }, //提交
    handleSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.addload = true;
          let { undeliverableReason, expressCompany } = this.ruleForm;
          let resultArray = [];
          this.ruleForm.areaList.forEach((selectedHierarchy) => {
            let result = {};
            this.getCodeAndName(
              this.optionsAddress,
              selectedHierarchy,
              result,
              0
            );
            resultArray.push(result);
          });
          console.log(resultArray, "111111111");
          let data = {
            companyId: this.ruleForm.expressCompany.toString(), //快递公司
            forbidlogisticsId: this.ruleForm.undeliverableReason, //不可发货原因
            platformId: this.ruleForm.associatedPlatform, //关联平台
            AreaJson: JSON.stringify(resultArray),
            start: this.josnlist.start,
          };
          if (this.titles == "编辑不可发货区域") {
            console.log(1111);
            data.id = this.josnpd.id;
            data.isDelete = false;
            this.edtUpShipments(data);
          }
          if (this.titles == "新增不可发货区域") {
            console.log(2222);
            this.addShipments(data); //接口请求
          }
        } else {
          return false;
        }
      });
    },

    edtUpShipments(data) {
      UpShipments(data).then((res) => {
        console.log(res);
        if (res.code == 10000) {
          this.addload = false;
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.closeModel();
        } else {
          this.addload = false;
          this.$message({
            message: res.msg,
            type: "error",
          });
          this.closeModel();
        }
      });
    },

    addShipments(data) {
      AddShipments(data).then((res) => {
        console.log(res);
        if (res.code == 10000) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.closeModel();
        } else {
          this.addload = false;
          this.$message({
            message: res.msg,
            type: "error",
          });
          this.closeModel();
        }
      });
    },

    //提交数据接口处理联选数据
    getCodeAndName(data, selectedNames, resultArr, level) {
      if (!data || !Array.isArray(data)) {
        return;
      }
      const selectedName = selectedNames[level];
      const selectedItem = data.find((item) => item.name === selectedName);
      // console.log(selectedItem, "selectedItem");
      if (selectedItem) {
        const codeFieldName = `${selectedItem.level}_id`;
        const nameFieldName = `${selectedItem.level}_name`;
        // console.log(resultArr[codeFieldName], "codeFieldName");
        resultArr[codeFieldName] = selectedItem.adcode;
        resultArr[nameFieldName] = selectedItem.name;

        if (selectedItem.districts && selectedNames.length > level + 1) {
          this.getCodeAndName(
            selectedItem.districts,
            selectedNames,
            resultArr,
            level + 1
          );
        }
      }
    },
  },
  components: {},
};
</script>
<style>
.pc-sel-area-cascader {
  border-radius: 0px !important;
  box-shadow: none !important;
}
.pc-sel-area-cascader .el-cascader-panel {
  width: 750px;
}
</style>
<style scoped lang="scss">
::v-deep {
  .el-form {
    width: 100%;
    .el-cascader,
    .el-select {
      width: 80%;
    }

    // .el-tag.el-tag--info:nth-child(n + 5) {
    //   width: 0;
    //   padding: 0;
    //   height: 0;
    //   margin: 0;
    //   color: rgba(255, 255, 255, 0);
    //   border-color: unset;
    //   background: none;
    //   i {
    //     display: none;
    //   }
    // }
  }
}
.selectAddress {
  .selectContent {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    ::v-deep .el-tag .el-icon-close {
      display: none;
    }
  }
}
.com-form {
  position: relative;
}
.btn-group {
  width: 750px;
  // border: 1px solid red;
  padding: 10px 10px;
  position: absolute;
  top: 235px;
  left: 1px;
  min-width: 180px;
  z-index: 6000;
  background: #fff;
  border: 1px solid #e4e7ed;
  //box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
</style>
